import React from 'react';

import styles from './footer.module.scss';
import SocialMediaLinks from '../social-media-links';

const Footer = () => (
  <footer className={styles.footer}>
    <SocialMediaLinks />
    <p className={styles.footerTextPrimary}>Design &copy; 2020 Rosemary Fenton</p>
    <p className={styles.footerTextSecondary}>Site by Jon Linnell</p>
  </footer>
);

export default Footer;
